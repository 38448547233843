@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-basic-layout-wrapper {
    height: 800px;
  }

  /* 输入框 */
  .ant-input {
    box-sizing: content-box !important;
    height: 22px;
  }

  .ant-select {
    height: 32px !important;
    line-height: 22px !important;
    margin: 0 20px -10px 0 !important;
  }

  .ant-picker-range {
    height: 32px !important;
  }

  .ant-picker-input {
    padding: 0 10px !important;
    margin: 0 !important;
    height: 32px !important;
    padding: 0 !important;
    /* box-sizing: border-box !important; */
  }

  .ant-picker-input > input {
    height: 32px !important;
    line-height: 32px !important;
    margin: 0 20px -10px 0 !important;
  }

  .ant-spin-nested-loading {
    margin-top: 20px;
  }

  /* 字体重叠 */
  /* 分页字体重叠 */
  .ant-pagination-options-size-changer.ant-select {
    width: 100px !important;
  }

  /* 考勤组样式重叠 */
  .ant-tree-select {
    width: 240px;
    height: 100% !important;
    display: block;
  }

  /* 班次管理时间选择 */
  .ie-plan-col {
    width: 220px !important;
    height: 22px !important;
    box-sizing: content-box !important;
  }

  .ie-plan-timePicker {
    width: 120px;
    height: 32px !important;
    margin-bottom: 0 !important;
  }

  .ie-attendance-span {
    display: inline-block !important;
    width: 220px !important;
  }

  /* banner图片 */
  .ie_banner_img {
    height: 247px;
  }

  /* 内容填充颜色 */
  .ie_content_bg {
    background: #f8f8f8;
  }

  /* 关于我们 */
  .ie_about_history_img {
    height: 304px;
  }
}
