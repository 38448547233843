.menuLogo {
  position: relative;
  height: 64px;
  padding-left: 12px;
  overflow: hidden;
  line-height: 64px;
  background: #28303f;
  cursor: pointer;
  transition: all 0.3s;
}

.menuLogo > .title {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  margin-left: 12px;
  color: #fff;
}

.menuLogo > img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
