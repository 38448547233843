/* 定制antd样式 */
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #22262f !important;
}

.ant-modal-confirm-confirm {
  top: 200px !important;
}

/* Input */
#root .ant-input {
  width: 180px;
}

/* form表单中的Input */
.ant-form .ant-input {
  width: 300px;
}

#root .ant-select {
  width: 180px;
}

.ant-form .ant-form-item .ant-select {
  width: 300px;
}

/* 表格居中 */
.ant-table-thead > tr > .ant-table-cell,
.ant-table-tbody > tr > td {
  text-align: center;
}

.ant-modal-header {
  background: #d41117 !important;
}

.ant-modal-title {
  color: #ffffff !important;
}

.ant-btn {
  border-radius: 15px !important;
}

.courseLearningDrawer .ant-drawer-wrapper-body {
  background: linear-gradient(0deg, rgba(243, 102, 56, 0.66), rgba(233, 134, 102, 0.81), #f54107) !important;
  border-radius: 9px !important;
}

.courseLearningDrawer .ant-drawer-header {
  background: #f35c2b !important;
  border-radius: 7px !important;
  border: 0 !important;
}
.courseLearningDrawer .ant-drawer-title {
  text-align: center !important;
  color: #ffffff !important;
}
.courseLearningRadio .ant-radio-button-wrapper {
  margin-right: 15px !important;
}
